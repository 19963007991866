import 'intl-pluralrules';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18n, { ResourceKey } from 'i18next';

import {
    brand,
    MissingInterpolationKeys,
    missingInterpolationHandler,
} from '@eon-home/react-library';

import en from '../assets/locales/en.json';
import de from '../assets/locales/de.json';
import it from '../assets/locales/it.json';
import sv from '../assets/locales/sv.json';
import pl from '../assets/locales/pl.json';
import hu from '../assets/locales/hu.json';
import nl from '../assets/locales/nl.json';
import hr from '../assets/locales/hr.json';

// @ts-ignore
import locales from './locales.mjs';
import { LOCALE } from '@tools/constants';

type Translation = Record<string, ResourceKey>;

const files: Translation = { en, de, it, sv, pl, hu, nl, hr };
const supportedLocales = brand.supportedLocales || [];
const translations: Translation = supportedLocales.length
    ? supportedLocales.reduce(
          (result: Translation, locale: string) => ({
              ...result,
              [locale]: files[locale],
          }),
          {} as Translation,
      )
    : files;

const resources = Object.keys(translations).reduce(
    (result: Record<string, Translation>, locale: string) => ({
        ...result,
        [locale]: { translation: translations[locale] },
    }),
    {},
);

const savedLocale = localStorage.getItem(LOCALE);
const lng: string =
    savedLocale && supportedLocales.includes(savedLocale)
        ? savedLocale
        : brand.defaultLocale || 'en';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng,
        backend: {
            loadPath: '../assets/locales',
        },
        preload: locales,
        resources,
        keySeparator: ':',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['navigator', 'localStorage', 'htmlTag'],
            caches: ['localStorage'],
            htmlTag: document.documentElement,
            lookupLocalStorage: 'locale',
        },
        /**
         * Replace all occurrences of brand specific variables in the translations
         * Currently supported are APPNAME and BRANDNAME which get replaced with the
         * corresponding value from the brand configuration object (brand.appName and brand.brand)
         */
        missingInterpolationHandler: (
            _: string,
            regExpMatches: string[],
        ): string =>
            missingInterpolationHandler(regExpMatches, {
                [MissingInterpolationKeys.APPNAME]: brand.appName,
                [MissingInterpolationKeys.BRANDNAME]: brand.brand,
            }),
    });

export default i18n;
